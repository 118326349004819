import { IRoutes } from '../../utils/routing/typings';
import ROUTE_KEYS from '../../routeKeys';
import DocumentCenterDashboard from './DocumentCenterDashboard';
import MedicalDocuments from './MedicalDocuments';
import ManageIdentificationDocument from './ManageIdentificationDocument';
import templates from '../appShell/templates';
import ManageAccessionAgreement from './ManageAccessionAgreement';
import ActivityReport from './ActivityReport';
import CompanyAndEmployeeReport from './CompanyAndEmployeeReport';
import WorkPostCards from './WorkPostCards';
import { ROUTE_GROUP } from '../../config/routeGroup.config';
import WorkPostCardWizard from './WorkPostCardWizard';
import { IWizardTemplateProps } from '../appShell/templates/WizardTemplate';
import { getPropertyFromRoutePayload } from '../../redux/location/selectors';
import RiskManagementDocuments from './RiskManagementDocuments';
import CoursesTemplate from './Courses/template';
import CoursesOverview from './Courses/Overview';
import CoursesPlanned from './Courses/Planned';
import CoursesFollowed from './Courses/Followed';
import CourseEnrollWizard from './Courses/CourseEnrollWizard';
import DocCenterTemplates from './Templates';
import DocCenterTemplatesCategoryDetail from './Templates/TemplatesCategoryDetail/index';
import { CUSTOM_DIMENSION_COURSE_ID } from '../../utils/logging/analytics/hitScopedCustomDimensions';
import {
    getCoursesOverviewDetail,
    getChildCourse,
    getCourseEnrollWizardCourse,
} from '../../redux/documentCenter/courses/selectors';
import { ASYNC_ENTITY_KEYS } from '../../models/entities/entities.models';
import {
    getSelectedTemplatesCategoryId,
    getSelectedTemplatesCategoryTitle,
} from '../../redux/entities/templates/selectors';
import { IFetchTemplatesCategoryDetail } from '../../models/documentCenter/templates';
import { fetchTemplatesCategoriesApiInputSelector } from '../../config/entities/asyncEntityKey2ApiConfigMap';
import { ILocationAction } from '../../models/general/redux';
import {
    conditionallyOverrideParentRouteKey,
    overrideParentRouteKeyToHomeDashboardIfDismissedCompany,
} from '../overrideParentRouteKey';
import { IState } from '../../redux';
import CourseIntroduction from './Courses/Overview/CourseIntroduction';
import CourseDetail from './Courses/Overview/CourseIntroduction/CourseDetail';

const overrideParentRouteKeyForCourses = (state: IState) =>
    conditionallyOverrideParentRouteKey(state, { checkDismissedCompany: true, checkLastDashboardRoute: true });

export const BASE_ROUTE = '/document-center';
const COURSES_ROUTE = `${BASE_ROUTE}/courses`;

const ROUTES: IRoutes = {
    [ROUTE_KEYS.R_DOCUMENT_CENTER]: {
        path: BASE_ROUTE,
        page: {
            key: 'DOCUMENT_CENTER',
            component: DocumentCenterDashboard,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'document_center',
        // requiredAccessLevels: [], // no access level needed because of the templates tile
        breadcrumbsLabel: {
            translationKey: 'document_center.overview.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.IS_DASHBOARD,
        ],
    },

    [ROUTE_KEYS.R_MEDICAL_DOCUMENTS]: {
        path: `${BASE_ROUTE}/medical-documents`,
        page: {
            key: 'MEDICAL_DOCS',
            component: MedicalDocuments,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'medical_documents',
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'document_center.medical_docs.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        overrideParentRouteKey: overrideParentRouteKeyToHomeDashboardIfDismissedCompany,
    },
    [ROUTE_KEYS.R_RISK_MGMT_DOCUMENTS]: {
        path: `${BASE_ROUTE}/risk-management-documents`,
        page: {
            key: 'RISK_MGMT_DOCS',
            component: RiskManagementDocuments,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'risk_management_documents',
        requiredAccessLevels: [
            { interventions: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'document_center.risk_mgmt_docs.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        overrideParentRouteKey: overrideParentRouteKeyToHomeDashboardIfDismissedCompany,
    },
    [ROUTE_KEYS.R_WORK_POST_CARDS]: {
        path: `${BASE_ROUTE}/work-post-cards`,
        page: {
            key: 'WORK_POST_CARDS',
            component: WorkPostCards,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'work_post_cards',
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'document_center.work_post_cards.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.WORKPOSTCARD_FETCH_BLANKFORMS_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL]: {
        path: `${BASE_ROUTE}/work-post-cards/:functionId`,
        page: {
            key: 'WORK_POST_CARDS_DETAIL',
            component: WorkPostCards,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'work_post_card_detail',
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'document_center.work_post_cards.detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.WORKPOSTCARD_FETCH_BLANKFORMS_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_EMPLOYEE_DETAIL]: {
        path: `${BASE_ROUTE}/work-post-cards/:functionId/employee/:id`,
        page: {
            key: 'WORK_POST_CARDS_DETAIL_EMPLOYEE_DETAIL',
            component: WorkPostCards,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'work_post_card_detail_employee_detail',
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'document_center.work_post_cards.employee_detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_FUNCTION_DETAIL_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.WORKPOSTCARD_FETCH_BLANKFORMS_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL]: {
        path: `${BASE_ROUTE}/work-post-cards/:functionId/employee/:id/medical-examinations/executed/:examinationId`,
        page: {
            key: 'WORK_POST_CARDS_DETAIL_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL',
            component: WorkPostCards,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'work_post_card_detail_medical_examination_executed_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.medical_examination.executed.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_FUNCTION_DETAIL_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EXECUTED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.WORKPOSTCARD_FETCH_BLANKFORMS_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL]: {
        path: `${BASE_ROUTE}/work-post-cards/:functionId/employee/:id/medical-examinations/planned/:timeSlotId`,
        page: {
            key: 'WORK_POST_CARDS_DETAIL_MEDICAL_EXAMINATIONS_PLANNED_DETAIL',
            component: WorkPostCards,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'work_post_card_detail_medical_examination_planned_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.medical_examination.planned.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_FUNCTION_DETAIL_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.PLANNED_EXAMINATION_DETAILS_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.WORKPOSTCARD_FETCH_BLANKFORMS_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_COURSES_PLANNED_DETAIL]: {
        path: `${BASE_ROUTE}/work-post-cards/:functionId/employee/:id/courses/planned/:coursesOrganizedId`,
        page: {
            key: 'WORK_POST_CARDS_DETAIL_COURSES_PLANNED_DETAIL',
            component: WorkPostCards,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'work_post_card_detail_courses_planned_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.courses.planned.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_FUNCTION_DETAIL_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.WORKPOSTCARD_FETCH_BLANKFORMS_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_WORK_POST_CARDS_DETAIL_COURSES_FOLLOWED_DETAIL]: {
        path: `${BASE_ROUTE}/work-post-cards/:functionId/employee/:id/courses/followed/:coursesOrganizedId`,
        page: {
            key: 'WORK_POST_CARDS_DETAIL_COURSES_FOLLOWED_DETAIL',
            component: WorkPostCards,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        virtualPage: 'work_post_card_detail_courses_followed_detail',
        breadcrumbsLabel: {
            translationKey: 'administration.employees.detail.courses.followed.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.COMPANY_FUNCTION_DETAIL_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.EMPLOYEE_DETAILS_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.WORKPOSTCARD_FETCH_BLANKFORMS_IF_NOT_AVAILABLE,
        ],
    },
    [ROUTE_KEYS.R_WORK_POST_CARD_NEW]: {
        path: `${BASE_ROUTE}/work-post-cards/new/:step`,
        page: {
            key: 'WORK_POST_CARDS_NEW',
            component: WorkPostCardWizard,
        },
        template: templates.WizardTemplate,
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'document_center.work_post_card_wizard.title',
            },
        } as IWizardTemplateProps,
        virtualPage: [
            'work_post_cards_new',
            (state) => getPropertyFromRoutePayload(state, 'step'),
        ],
        requiredAccessLevels: [
            { employee: 'R' },
        ],
        groups: [
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
    [ROUTE_KEYS.R_TEMPLATES]: {
        path: `${BASE_ROUTE}/templates`,
        page: {
            key: 'TEMPLATES',
            component: DocCenterTemplates,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'templates',
        breadcrumbsLabel: {
            translationKey: 'document_center.templates.breadcrumbs_label',
        },
        entitiesToFetch: [{
            entityKey: ASYNC_ENTITY_KEYS.templates,
            refreshMode: 'never',
        }],
    },
    [ROUTE_KEYS.R_TEMPLATES_CATEGORY_DETAIL]: {
        path: `${BASE_ROUTE}/templates/:categoryId`,
        page: {
            key: 'TEMPLATES_CATEGORY_DETAIL',
            component: DocCenterTemplatesCategoryDetail,
        },
        template: templates.WithMenuTemplate,
        virtualPage: [
            'templates_category_detail',
            (state) => {
                const categoryId = getSelectedTemplatesCategoryId(state);
                return categoryId ? categoryId.toString() : 'noCategoryId';
            },
        ],
        breadcrumbsLabel: {
            translationKey: 'document_center.templates.detail.breadcrumbs_label',
            translationPlaceholdersSelector: (state) => ({
                category: getSelectedTemplatesCategoryTitle(state),
            }),
        },
        entitiesToFetch: [{
            entityKey: ASYNC_ENTITY_KEYS.templatesCategory,
            // resetDataOnTrigger: true,
            apiInputSelector: ({ state, action }) => {
                const categoryDetailAction = action as ILocationAction<{ categoryId: string }>;
                const apiInput: IFetchTemplatesCategoryDetail = {
                    ...fetchTemplatesCategoriesApiInputSelector({ state }),
                    categoryId: Number(categoryDetailAction.payload.categoryId),
                };
                return apiInput;
            },
        }],
    },

    [ROUTE_KEYS.R_MANAGE_IDENTIFICATION_DOCUMENT]: {
        path: `${BASE_ROUTE}/identification-document`,
        page: {
            key: 'MANAGE_IDENTIFICATION_DOC',
            component: ManageIdentificationDocument,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'identification_document',
        requiredAccessLevels: [
            { company: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'document_center.identification_doc.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_ACCESSION_AGREEMENT]: {
        path: `${BASE_ROUTE}/accession-agreement`,
        page: {
            key: 'ACCESSION_AGREEMENT',
            component: ManageAccessionAgreement,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'accession_agreement',
        requiredAccessLevels: [
            { company: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'document_center.accession_agreement.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_COMPANY_AND_EMPLOYEE_REPORT]: {
        path: `${BASE_ROUTE}/company-and-employee-report`,
        page: {
            key: 'COMPANY_AND_EMPLOYEE_REPORT',
            component: CompanyAndEmployeeReport,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'company_and_employee_report',
        requiredAccessLevels: [
            { company: 'R', employee: 'R' }, // BOTH needed (.. AND ..)
        ],
        breadcrumbsLabel: {
            translationKey: 'document_center.company_and_employee_report.breadcrumbs_label',
        },
    },
    [ROUTE_KEYS.R_ACTIVITY_REPORT]: {
        path: `${BASE_ROUTE}/activity-report`,
        page: {
            key: 'ACTIVITY_REPORT',
            component: ActivityReport,
        },
        template: templates.WithMenuTemplate,
        virtualPage: 'activity_report',
        requiredAccessLevels: [
            { company: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'document_center.activity_report.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        overrideParentRouteKey: overrideParentRouteKeyToHomeDashboardIfDismissedCompany,
    },
    [ROUTE_KEYS.R_COURSES_OVERVIEW]: {
        path: `${COURSES_ROUTE}/overview`,
        page: {
            key: 'COURSES_OVERVIEW',
            component: CoursesOverview,
        },
        template: CoursesTemplate,
        requiredAccessLevels: [
            { interventions: 'R' },
        ],
        virtualPage: 'courses_overview',
        breadcrumbsLabel: {
            translationKey: 'document_center.courses.overview.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        overrideParentRouteKey: overrideParentRouteKeyForCourses,
        entitiesToFetch: [{
            entityKey: ASYNC_ENTITY_KEYS.courseLinks,
            refreshMode: 'never',
        }],
    },
    [ROUTE_KEYS.R_COURSES_DETAIL_INTRO]: {
        path: `${COURSES_ROUTE}/overview/:nodeId`,
        page: {
            key: 'COURSES_DETAIL_INTRO',
            component: CourseIntroduction,
        },
        template: templates.WithMenuTemplate,
        requiredAccessLevels: [
            { interventions: 'R' },
        ],
        virtualPage: 'courses_detail_intro',
        extraDataToLog: (state) => ({
            [CUSTOM_DIMENSION_COURSE_ID]: getPropertyFromRoutePayload(state, 'nodeId'),
        }),
        breadcrumbsLabel: {
            translationKey: 'document_center.courses.overview.detail.intro.breadcrumbs_label',
            translationPlaceholdersSelector: (state: IState) => {
                const selectedCourse = getCoursesOverviewDetail(state);
                return {
                    name: selectedCourse.course.name,
                };
            },
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
    [ROUTE_KEYS.R_COURSES_DETAIL_COURSE]: {
        path: `${COURSES_ROUTE}/overview/:nodeId/:childNodeId`,
        page: {
            key: 'COURSES_DETAIL_COURSE',
            component: CourseDetail,
        },
        requiredAccessLevels: [
            { interventions: 'R' },
        ],
        template: templates.WithMenuTemplate,
        virtualPage: ['courses_detail_course'],
        extraDataToLog: (state) => ({
            [CUSTOM_DIMENSION_COURSE_ID]: getPropertyFromRoutePayload(state, 'childNodeId'),
        }),
        breadcrumbsLabel: {
            translationKey: 'document_center.courses.overview.detail.child.breadcrumbs_label',
            translationPlaceholdersSelector: (state: IState) => {
                const selectedChildCourseId = getPropertyFromRoutePayload(state, 'childNodeId');
                const selectedChildCourse = getChildCourse(state, selectedChildCourseId);
                return {
                    name: selectedChildCourse.course.name,
                };
            },
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
    [ROUTE_KEYS.R_COURSES_PLANNED]: {
        path: `${COURSES_ROUTE}/planned`,
        page: {
            key: 'COURSES_PLANNED',
            component: CoursesPlanned,
        },
        template: CoursesTemplate,
        requiredAccessLevels: [
            { interventions: 'R' },
        ],
        virtualPage: 'courses_planned',
        breadcrumbsLabel: {
            translationKey: 'document_center.courses.planned.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        overrideParentRouteKey: overrideParentRouteKeyForCourses,
    },
    [ROUTE_KEYS.R_COURSES_PLANNED_DETAIL]: {
        path: `${COURSES_ROUTE}/planned/:coursesOrganizedId`,
        page: {
            key: 'COURSES_PLANNED_DETAIL',
            component: CoursesPlanned,
        },
        template: CoursesTemplate,
        virtualPage: 'courses_planned_detail',
        requiredAccessLevels: [
            { interventions: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'document_center.courses.planned.detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
    [ROUTE_KEYS.R_COURSES_FOLLOWED]: {
        path: `${COURSES_ROUTE}/followed`,
        page: {
            key: 'COURSES_FOLLOWED',
            component: CoursesFollowed,
        },
        template: CoursesTemplate,
        requiredAccessLevels: [
            { interventions: 'R' },
        ],
        virtualPage: 'courses_followed',
        breadcrumbsLabel: {
            translationKey: 'document_center.courses.followed.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        overrideParentRouteKey: overrideParentRouteKeyForCourses,
    },
    [ROUTE_KEYS.R_COURSES_FOLLOWED_DETAIL]: {
        path: `${COURSES_ROUTE}/followed/:coursesOrganizedId`,
        page: {
            key: 'COURSES_FOLLOWED_DETAIL',
            component: CoursesFollowed,
        },
        template: CoursesTemplate,
        virtualPage: 'courses_followed_detail',
        requiredAccessLevels: [
            { interventions: 'R' },
        ],
        breadcrumbsLabel: {
            translationKey: 'document_center.courses.followed.detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    },
    [ROUTE_KEYS.R_COURSES_FOLLOWED_DETAIL_ATTENDANT]: {
        path: `${COURSES_ROUTE}/followed/:coursesOrganizedId/attendance/:employeeId`,
        page: {
            key: 'COURSES_FOLLOWED_DETAIL_ATTENDANT',
            component: CoursesFollowed,
        },
        template: CoursesTemplate,
        virtualPage: 'courses_followed_detail_attendant',
        requiredAccessLevels: [
            { interventions: 'R' },
        ],
        groups: [
            ROUTE_GROUP.FOLLOWED_COURSE_DETAIL_FETCH_IF_NOT_AVAILABLE,
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        breadcrumbsLabel: {
            translationKey: 'document_center.courses.followed.attendant_detail.breadcrumbs_label',
        },
    },
    /* [ROUTE_KEYS.R_COURSES_CERTIFICATES]: {
        path: `${COURSES_ROUTE}/certificates`,
        page: {
            key: 'COURSES_CERTIFICATES',
            component: Certificates,
        },
        template: CoursesTemplate,
        requiredAccessLevels: [
            { interventions: 'R' },
        ],
        virtualPage: 'coures_certificates',
        breadcrumbsLabel: {
            translationKey: 'document_center.courses.certificates.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
        overrideParentRouteKey: overrideParentRouteKeyForCourses,
    }, */
    /* [ROUTE_KEYS.R_COURSES_CERTIFICATES_DETAIL]: {
        path: `${COURSES_ROUTE}/certificates/:code`,
        page: {
            key: 'COURSES_CERTIFICATES_DETAIL',
            component: Certificates,
        },
        template: CoursesTemplate,
        requiredAccessLevels: [
            { interventions: 'R' },
        ],
        virtualPage: 'coures_certificates_detail',
        breadcrumbsLabel: {
            translationKey: 'document_center.courses.certificates.detail.breadcrumbs_label',
        },
        groups: [
            ROUTE_GROUP.ALLOWED_IF_DISMISSED_COMPANY,
        ],
    }, */
    [ROUTE_KEYS.R_COURSE_ENROLL_NEW]: {
        path: `${COURSES_ROUTE}/new/:step`,
        page: {
            key: 'COURSE_ENROLL_NEW',
            component: CourseEnrollWizard,
        },
        template: templates.WizardTemplate,
        templateProps: {
            wizardTitle: {
                titleTranslationKey: 'document_center.courses.new.title',
            },
        } as IWizardTemplateProps,
        virtualPage: [
            'course_enroll_new',
            (state) => getPropertyFromRoutePayload(state, 'step'),
        ],
        extraDataToLog: (state) => ({
            [CUSTOM_DIMENSION_COURSE_ID]: getCourseEnrollWizardCourse(state).id,
        }),
        requiredAccessLevels: [
            { interventions: 'W' },
        ],
        groups: [
            ROUTE_GROUP.IS_PART_OF_WIZARD,
        ],
    },
};

export default ROUTES;
