import React, { PureComponent, MouseEvent } from 'react';
import { clone } from 'ramda';

import '../medical-examinations.scss';
import {
    IExecutedMedicalExamination,
} from '../../../../models/interventions/medicalExaminations';
import {
    getExecutedMedicalExaminationsAsyncInfo,
    getExecutedMedicalExaminations,
    getSelectedExecutedMedicalExaminationAsyncInfo,
    getSelectedExecutedMedicalExamination,
    getExaminationDocumentsAndFilesAsyncInfo,
} from '../../../../redux/medicalExamination/selectors';
import ROUTE_KEYS from '../../../../routeKeys';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps,
    IShouldRenderShowAllButtonProps,
    IClientSideFilterOfListDataProps,
    ITransformToActiveFiltersProps,
    IRenderSearchContentProps, IRenderFilterContentProps,
    IRenderDetailHeaderProps, IRenderDetailContentProps, IFetchAllExportDataProps,
} from '../../../common/widget/MasterWithDetail/typings';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import CheckboxesOrTypeaheadFilter from '../../../common/input/CheckboxesOrTypeaheadFilter';
import TextInput from '../../../common/input/TextInput';
import ListWithSorting from '../../../common/list/ListWithSorting';
import Translate from '../../../common/Translate';
import { ListColumns, ListItem, SortType, ISortedColumn, SortOrder } from '../../../../models/general/list';
import { DEFAULT_EXECUTED_MEDICAL_EXAMINATIONS_FILTERS } from '../../../../api/interventions/medicalExaminations.api';
import { formatPersonNameFormal } from '../../../../utils/formatting/formatPerson';
import { separateStringList } from '../../../../utils/core/string/separatedStringList';
import getUniqueTypeaheadFilterValuesFromListItems
    from '../../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import {
    formatDateInLongFormat,
    formatDateForDisplay,
} from '../../../../utils/formatting/formatDate';
import { DetailHeader, DetailContent } from './detail';
import Icon from '../../../common/icons/Icon';
import ListItemActions from '../../../common/list/ListItemActions';
import Button from '../../../common/buttons/Button';
import { connect } from '../../../index';
import { getDocumentAsyncInfo } from '../../../../redux/employee/documents/selectors';
import { AsyncStatus } from '../../../../models/general/redux';
import { fetchExaminationDocumentsAndFilesActions } from '../../../../redux/medicalExamination/actions';
import NoDocumentsFoundErrorDialog from '../../../common/modals/NoDocumentsFoundErrorDialog';
import Loader from '../../../common/waiting/Loader';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import StartEndDateFilter from '../../../common/input/StartEndDateFilter';
import { startEndDateSchema } from '../../../common/input/StartEndDateFilter/startEndDateSchema';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';
import { doExportExecutedExaminationsCall } from '../../../../redux/medicalExamination/exportExaminationsHelper';
import { getCsvFilename } from '../../../../utils/file/csv/exportListDataToCsv';
import api from '../../../../api';
import { getStore } from '../../../../redux/storeNoCircularDependencies';

const BASE_NAME = 'executed-med-exams';
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;
const EXPORT_BASE_FILENAME = 'medical-examinations-executed';
const CLASS_NAME = 'MedicalExaminationsExecuted';

interface IPrivateProps {
    isDownloadingDocument: boolean;
    onDownloadClick: (employeeCustomerId: number, examinationId: number) => void;
}

interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    reasonIds: string;
    isShowAll: boolean;
}

interface IColumnNames {
    employee: string;
    employeeCustomerId: number;
    examinationReason: string;
    examinationReasonCode: string;
    examinationDate: string;
    examinationDateSort: string;
    actions: string;
}

type TExaminationsListProps = IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>;

const COLUMNS: ListColumns<IColumnNames> = {
    employee: {
        label: <Translate msg="interventions.medical_examinations.executed.columns.employee" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 40,
    },
    employeeCustomerId: {
        hide: true,
        percentWidth: null,
    },
    examinationDate: {
        label: <Translate msg="interventions.medical_examinations.executed.columns.examination_date" />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.examinationDateSort,
        percentWidth: 20,
    },
    examinationDateSort: {
        hide: true,
        percentWidth: null,
    },
    examinationReason: {
        label: <Translate msg="interventions.medical_examinations.executed.columns.examination_reason" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 31,
    },
    examinationReasonCode: {
        hide: true,
        percentWidth: null,
    },
    actions: {
        sortable: false,
        percentWidth: 9,
    },
};

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'examinationDate',
    sortOrder: SortOrder.Descending,
};

function ExecutedMedicalExaminationsComp(props: IPrivateProps) {
    return (
        <MasterWithDetail
            baseName={BASE_NAME}
            className={CLASS_NAME}
            getDefaultQueryParams={getDefaultQueryParams}
            masterConfig={{
                routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_EXECUTED,
                asyncInfoSelector: getExecutedMedicalExaminationsAsyncInfo,
                dataSelector: getExecutedMedicalExaminations,
                transformData: mapMedicalExaminationsToListItems,
                transformFilterValuesToActiveFilters,
                renderContent: (renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                    <ExaminationsList {...renderProps} {...props} />,
                clientSideSearchOfListData: {
                    searchFilterName: 'search',
                    columnsConfig: COLUMNS,
                },
                clientSideFilterOfListData,
                filterValidationSchema: startEndDateSchema,
            }}
            detailConfig={{
                routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_EXECUTED_DETAIL,
                asyncInfoSelector: getSelectedExecutedMedicalExaminationAsyncInfo,
                idRouteParamName: 'examinationId',
                dataSelector: getSelectedExecutedMedicalExamination,
                renderHeader: (renderProps: IRenderDetailHeaderProps<IExecutedMedicalExamination>) =>
                    <DetailHeader {...renderProps} />,
                renderContent: (renderProps: IRenderDetailContentProps<IExecutedMedicalExamination>) =>
                    <DetailContent {...renderProps} />,
            }}
            headerConfig={{
                renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                    <SearchContent {...renderProps} />,
                renderFilterContent:
                    (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                        <FilterContent {...renderProps} />,
                exportButton: {
                    baseFilename: 'medical-examinations-executed',
                    fetchAllExportData: {
                        isFetchNeeded: isFetchAllExportDataNeeded,
                        apiCall: fetchAllExportDataApiCall,
                        willResultAlreadyBeAFile: true,
                    },
                },
            }}
            footerConfig={{
                shouldRenderShowAllButton,
            }}
        />
    );

    function isFetchAllExportDataNeeded(): boolean {
        return true;
    }
}

function mapMedicalExaminationsToListItems(masterData: IExecutedMedicalExamination[]): ListItem<IColumnNames>[] {
    return masterData
        .map((examination) => ({
            id: examination.id,
            columns: {
                employee: formatPersonNameFormal(examination.employee),
                employeeCustomerId: examination.employee.id,
                examinationReason: examination.examinationReason.title,
                examinationReasonCode: examination.examinationReason.code,
                examinationDate: formatDateInLongFormat(examination.examinationDate),
                examinationDateSort: examination.examinationDate,
                conclusionCodeId: examination.conclusionCodeId,
                conclusionPregnancy: examination.conclusionPregnancy,
                conclusionPregnancySentences: examination.conclusionPregnancySentences,
                conclusionSigns: examination.conclusionSigns,
                actions: null,
            },
        }));
}

class ExaminationsList extends PureComponent<TExaminationsListProps & IPrivateProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props) {
        super(props);

        this.onDownloadClick = this.onDownloadClick.bind(this);

        this.columns.actions.render = (listItem: ListItem<IColumnNames>, index) => {
            return (
                <ListItemActions>
                    <Button
                        id="download-file-button"
                        onClick={(e) => this.onDownloadClick(e, listItem)}
                    >
                        <span><Translate msg="interventions.medical_examinations.executed.actions.download" /></span>
                        <Icon circle typeName="download-file" />
                    </Button>
                </ListItemActions>
            );
        };
    }

    private onDownloadClick(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames>) {
        e.preventDefault();
        e.stopPropagation();

        this.props.onDownloadClick(
            listItem.columns.employeeCustomerId as number,
            listItem.id as number,
        );
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            onItemSelected,
            selectedItemId,
            filterValues,
            footer,
            isDownloadingDocument,
        } = this.props;

        return (
            <>
                <p className={`${CLASS_NAME}__pre-list`}>
                    <Translate raw msg="interventions.medical_examinations.executed.pre_table" />
                </p>
                <ListWithSorting
                    columns={this.columns}
                    items={clientSideFilteredlistItems}
                    name={BASE_NAME}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    selectedItemIds={selectedItemId ? [selectedItemId] : []}
                    onItemRowClicked={onItemSelected}
                    initialSort={INITIAL_SORT}
                    maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                    footer={footer}
                />
                <NoDocumentsFoundErrorDialog
                    asyncInfoSelector={getExaminationDocumentsAndFilesAsyncInfo}
                    isMedicalExaminationDocument
                />
                <NoDocumentsFoundErrorDialog
                    asyncInfoSelector={getDocumentAsyncInfo}
                    isMedicalExaminationDocument
                />
                <Loader show={isDownloadingDocument} />
            </>
        );
    }
}

function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues, isFilterSet } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.reasonIds)) {
                return true;
            }

            const reasonIds = separateStringList(filterValues.reasonIds);
            return reasonIds.includes(listItem.columns.examinationReason.toString());
        });
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('interventions.medical_examinations.executed.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="interventions.medical_examinations.executed.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
        // translator,
        masterData: allListItems,
    } = renderProps;

    const possibleReasons = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'examinationReason',
        'examinationReason',
    );

    return (
        <div>
            {possibleReasons.length > 0 &&
                <CheckboxesOrTypeaheadFilter
                    filterName="reason"
                    labelTranslationKey="interventions.medical_examinations.executed.filter.reason"
                    possibleFilterItems={possibleReasons}
                    actualFilterValue={formRenderProps.values.reasonIds}
                    onChange={(newFilterValue) => formRenderProps.setFieldValue(
                        'reasonIds',
                        newFilterValue,
                    )}
                />
            }

            <StartEndDateFilter
                translationKeyPrefix="interventions.medical_examinations.executed.filter"
                formRenderProps={formRenderProps}
            />
        </div>
    );
}

function getDefaultQueryParams({ isShowAll }: { isShowAll: boolean }) {
    return isShowAll ? {
        ...DEFAULT_EXECUTED_MEDICAL_EXAMINATIONS_FILTERS,
        isShowAll,
    } : DEFAULT_EXECUTED_MEDICAL_EXAMINATIONS_FILTERS;
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.medical_examinations.executed.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_EXECUTED_MEDICAL_EXAMINATIONS_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_EXECUTED_MEDICAL_EXAMINATIONS_FILTERS.startDate,
            },
            reasonIds: {
                show: true,
                translationKeySuffixOverride: 'reason',
                multiple: {
                    enable: true,
                    filterValueLabelFromListItem: {
                        columnNameToReturn: 'examinationReason',
                        searchColumnName: 'examinationReasonCode',
                    },
                },
            },
        },
    });
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const downloadAsyncInfo = getExaminationDocumentsAndFilesAsyncInfo(state);
        const documentAsyncInfo = getDocumentAsyncInfo(state);
        const isDownloadingDocument = documentAsyncInfo.status === AsyncStatus.Busy
            || downloadAsyncInfo.status === AsyncStatus.Busy;

        return {
            isDownloadingDocument,
        };
    },
    dispatchProps: (dispatch) => ({
        onDownloadClick: (employeeCustomerId: number, examinationId: number) => {
            dispatch(fetchExaminationDocumentsAndFilesActions.trigger({
                employeeCustomerId,
                examinationId,
            }));
        },
    }),
})(ExecutedMedicalExaminationsComp);

async function fetchAllExportDataApiCall(fetchProps: IFetchAllExportDataProps<IFilterValues>) {
    const document = await doExportExecutedExaminationsCall({
        state: getStore().getState(),
        api,
        defaultFilename: getCsvFilename(EXPORT_BASE_FILENAME),
        filter: {
            startDate: fetchProps.filterValues.startDate,
            endDate: fetchProps.filterValues.endDate,
        },
    });

    return document;
}
