import React, { PureComponent, ReactNode, ChangeEvent } from 'react';
import classNames from 'classnames';
import Flatpickr from 'react-flatpickr';
import flatpickr from 'flatpickr';
import '../date-picker.scss';
import { POPPER_CONTAINER_ELEMENT_ID } from '../../../../../config/dom.config';
import {
    formatDateForBackend,
    formatDateForDisplay,
} from '../../../../../utils/formatting/formatDate';
import debounce, { TDebounced } from '../../../../../utils/core/debounce';
import getOrCreateElementInBodyById from '../../../../../utils/dom/getOrCreateElementInBodyById';
import isInViewport from '../../../../../utils/dom/isInViewport';
import findClosestScrollContainer from '../../../../../utils/dom/findClosestScrollContainer';
import Icon from '../../../icons/Icon';
import customDayPlugin, { ICustomDayStyle } from './customDayPlugin';
import customWeekSelectPlugin from './customWeekSelectPlugin';
import localeToFlatpickrLocale from '../../../../../utils/libs/flatpickr/localeToFlatpickrLocale';
import { Instance } from 'flatpickr/dist/types/instance';
import { isValidDisplayDate, isValidBackendDate } from '../../../../../utils/core/date/isValid';
import TranslatorContext from '../../../../appShell/contexts/TranslatorContext';
import MaskedTextInput from '../../../input/MaskedTextInput';

const POPPER_CONTAINER_ELEMENT = getOrCreateElementInBodyById(POPPER_CONTAINER_ELEMENT_ID);

interface IDatePickerProps {
    id: string;
    name?: string;
    onChange?: (formattedDate: string) => void;
    onMonthChange?: (month: number, year: number) => void;
    onYearChange?: (month: number, year: number) => void;
    value: string;
    inlineCalendar?: boolean;
    hideTextInput?: boolean;
    placeholder?: string;
    isInvalid?: boolean;
    minDate?: string;
    maxDate?: string;
    children?: ReactNode;
    disabled?: boolean;
    customDayStyles?: ICustomDayStyle[];
    highlightWeek?: boolean;
    zIndex?: string;
}

interface IDatePickerState {
    hasChangedAfterSetToInvalid: boolean;
    textInput: string;
    focused: boolean;
}

const DATE_FORMAT = 'd/m/Y';
const ARIA_DATE_FORMAT = 'F j, Y';
const MASK = [
    /\d/, /\d/, '/', /\d/, /\d/, '/',
    /\d/, /\d/, /\d/, /\d/,
];
const DEFAULT_PLACEHOLDER = 'DD/MM/YYYY';

export default class DatePicker extends PureComponent<IDatePickerProps, IDatePickerState> {
    private inputEl: HTMLInputElement;
    private datePickerEl: HTMLElement;
    private onContainerScrollDebounced: TDebounced;
    private onWindowScrollDebounced: TDebounced;
    private scrollContainer: HTMLElement | Window;
    private plugins = [
        customWeekSelectPlugin({ isEnabled: () => this.props.highlightWeek }),
        customDayPlugin({ getCustomDayStyles: () => this.props.customDayStyles }),
    ];

    constructor(props: IDatePickerProps) {
        super(props);

        this.state = {
            hasChangedAfterSetToInvalid: false,
            textInput: formatTextInput(props.value),
            focused: false,
        };

        this.handleOnChange = this.handleOnChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onYearChange = this.onYearChange.bind(this);

        this.onContainerScrollDebounced = debounce(this.onScroll, 10);
        this.onWindowScrollDebounced = debounce(this.onScroll, 10);

        this.onOpenHandler = this.onOpenHandler.bind(this);
        this.onBlurHandler = this.onBlurHandler.bind(this);

        this.selectInputFieldOnNextFrameAfterOtherFocusEvents =
            this.selectInputFieldOnNextFrameAfterOtherFocusEvents.bind(this);
    }

    public render() {
        const {
            inlineCalendar, hideTextInput,
            isInvalid, id, minDate, maxDate, children, disabled,
            placeholder,
        } = this.props;
        const { hasChangedAfterSetToInvalid, textInput } = this.state;

        const isInputInvalid = textInput && !isValidDisplayDate(textInput);

        const datePickerClasses = classNames('DatePicker', {
            'hide-input': hideTextInput,
            dirty: textInput.length > 0,
            invalid: isInputInvalid || isInvalid,
            'reset-color': !isInputInvalid && (!hasChangedAfterSetToInvalid || !isInvalid),
            focused: this.state.focused,
        });

        return (
            <TranslatorContext.Consumer>
                {({ locale }) => {
                    const flatpickrLocale = localeToFlatpickrLocale(locale);
                    return (
                        <span
                            className={datePickerClasses}
                            ref={(ref) => this.datePickerEl = ref}
                        >
                            <Flatpickr
                                value={isValidDisplayDate(textInput) ? textInput : ''}
                                onChange={this.handleOnChange}
                                options={{
                                    dateFormat: DATE_FORMAT,
                                    locale: flatpickrLocale,
                                    closeOnSelect: inlineCalendar ? false : true,
                                    inline: inlineCalendar,
                                    allowInput: true,
                                    weekNumbers: true,
                                    prevArrow: '<i class="prev"/>',
                                    nextArrow: '<i class="next"/>',
                                    minDate: minDate && formatDateForDisplay(minDate),
                                    maxDate: maxDate && formatDateForDisplay(maxDate),
                                    wrap: inlineCalendar ? false : true,
                                    appendTo: !inlineCalendar ? POPPER_CONTAINER_ELEMENT : undefined,
                                    ariaDateFormat: ARIA_DATE_FORMAT,
                                    onMonthChange: this.onMonthChange,
                                    onYearChange: this.onYearChange,
                                    plugins: this.plugins,
                                    onOpen: this.onOpenHandler,
                                }}
                            >
                                <MaskedTextInput
                                    id={id}
                                    name={id}
                                    isInvalid={isInputInvalid || isInvalid}
                                    disabled={disabled}
                                    onChange={this.onInputChange}
                                    value={textInput}
                                    mask={MASK}
                                    disableAutoComplete
                                    placeholder={placeholder || DEFAULT_PLACEHOLDER}
                                    inputRef={(el) => this.inputEl = el}
                                    onBlur={this.onBlurHandler}
                                />
                                {children}
                                <a data-toggle>
                                    <Icon typeName="calendar-date" />
                                </a>
                            </Flatpickr>
                        </span>
                    );
                }}
            </TranslatorContext.Consumer>
        );
    }

    public componentDidMount() {
        if (!this.props.inlineCalendar) {
            this.scrollContainer = findClosestScrollContainer(this.inputEl);
            this.scrollContainer.addEventListener('scroll', this.onContainerScrollDebounced);
            if (this.scrollContainer !== window) {
                window.addEventListener('scroll', this.onWindowScrollDebounced);
            }
            if (this.props.zIndex) {
                const instance = this.getFlatpickrInstance();
                if (instance) {
                    instance.calendarContainer.style.zIndex = this.props.zIndex;
                }
            }
        }
    }

    public componentWillUnmount() {
        this.onContainerScrollDebounced.cancel();
        this.onWindowScrollDebounced.cancel();
        if (this.scrollContainer) {
            this.scrollContainer.removeEventListener('scroll', this.onContainerScrollDebounced);
            window.removeEventListener('scroll', this.onWindowScrollDebounced);
        }
    }

    public componentDidUpdate(prevProps: IDatePickerProps) {
        if (!this.props.inlineCalendar && !this.scrollContainer) {
            this.scrollContainer = findClosestScrollContainer(this.inputEl);
        }

        if (this.props.value && prevProps.value !== this.props.value) {
            // Compare that the actual parsed dates of the value and text input are different
            // For example leading zeros are always set on the value but cannot be set on the textinput
            // To avoid zeros to be injected automtically this check is done
            const parsedTextInput = parseFlatPickrDate(this.state.textInput);
            if (this.props.value !== parsedTextInput) {
                this.setState({
                    textInput: formatTextInput(this.props.value),
                });
            }
        }

        if (prevProps.isInvalid !== this.props.isInvalid) {
            this.setState({
                hasChangedAfterSetToInvalid: false,
            });
        } else if (!this.state.hasChangedAfterSetToInvalid && this.props.value !== prevProps.value) {
            this.setState({
                hasChangedAfterSetToInvalid: true,
            });
        }

        if (this.props.customDayStyles !== prevProps.customDayStyles) {
            const flatpickrInstance = this.getFlatpickrInstance();
            if (flatpickrInstance) {
                flatpickrInstance.redraw();
            }
        }
    }

    private handleOnChange(dates: Date[]) {
        const { onChange } = this.props;

        if (typeof onChange === 'function') {
            onChange(dates[0] ? formatDateForBackend(dates[0]) : null);
        }
    }

    private onInputChange(e: ChangeEvent<HTMLInputElement>) {
        const dateString = e.target.value;

        this.setState({
            textInput: dateString,
        });

        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            if (!dateString) {
                return onChange(null);
            }
            const date = parseFlatPickrDate(dateString);
            onChange(date);
        }
    }

    private onScroll() {
        const flatpickrInstance = this.getFlatpickrInstance();
        if (flatpickrInstance) {
            if (!isInViewport(this.inputEl, this.scrollContainer)) {
                flatpickrInstance.close();
            } else {
                flatpickrInstance._positionCalendar(this.inputEl);
            }
        }
    }

    private getFlatpickrInstance() {
        if (!this.datePickerEl) {
            return null;
        }
        const flatpickerElement = this.datePickerEl.children[0] as HTMLElement & { _flatpickr: flatpickr.Instance };
        return flatpickerElement._flatpickr;
    }

    private onMonthChange(dates: Date[], currentDateString: string, flatpickrInstance: Instance) {
        const { onMonthChange } = this.props;
        if (typeof onMonthChange === 'function') {
            onMonthChange(flatpickrInstance.currentMonth, flatpickrInstance.currentYear);
        }
    }

    private onYearChange(dates: Date[], currentDateString: string, flatpickrInstance: Instance) {
        const { onYearChange } = this.props;

        if (typeof onYearChange === 'function') {
            onYearChange(flatpickrInstance.currentMonth, flatpickrInstance.currentYear);
        }
    }

    private onOpenHandler() {
        this.setState({ focused: true });
        this.selectInputFieldOnNextFrameAfterOtherFocusEvents();
    }

    private onBlurHandler() {
        this.setState({ focused: false });
    }

    private selectInputFieldOnNextFrameAfterOtherFocusEvents() {
        window.requestAnimationFrame(() => this.inputEl && this.inputEl.select());
    }
}

function parseFlatPickrDate(dateString: string) {
    const date = flatpickr.parseDate(dateString, DATE_FORMAT);
    const formattedDate = formatDateForBackend(date);
    if (isValidDisplayDate(dateString) && !formattedDate.includes(Number.NaN.toString())) {
        return formattedDate;
    }
    return null;
}

function formatTextInput(value: string) {
    if (value) {
        const formattedDate = formatDateForDisplay(value);
        if (isValidBackendDate(value) && !formattedDate.includes(Number.NaN.toString())) {
            return formattedDate;
        }
        return value;
    }

    return '';
}
