import React, { PureComponent, MouseEvent } from 'react';
import './followed-courses.scss';
import { clone } from 'ramda';
import { connect } from '../../../index';
import ROUTE_KEYS from '../../../../routeKeys';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps,
    IClientSideFilterOfListDataProps,
    ITransformToActiveFiltersProps,
    IShouldRenderShowAllButtonProps,
    IRenderSearchContentProps,
    IRenderFilterContentProps,
    IRenderDetailContentProps,
    IRenderDetailHeaderProps,
    IRenderDetailFooterProps,
} from '../../../common/widget/MasterWithDetail/typings';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import ListWithSorting from '../../../common/list/ListWithSorting';
import Translate from '../../../common/Translate';
import { ISortedColumn, ListColumns, ListItem, SortOrder, SortType } from '../../../../models/general/list';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import { ICourse, ICourseSessionAttendant } from '../../../../models/documentCenter/courses';
import { formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import {
    getCoursesFollowedAsyncInfo,
    getCoursesFollowed,
    getSelectedFollowedCourse,
    getSelectedCourseSessionAttendant,
    getFetchCourseSessionCertificatesAsyncInfo,
} from '../../../../redux/documentCenter/courses/selectors';
import { formatAddress } from '../../../../utils/formatting/formatAddress';
import { startEndDateSchema } from '../../../common/input/StartEndDateFilter/startEndDateSchema';
import StartEndDateFilter from '../../../common/input/StartEndDateFilter';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';
import { DEFAULT_COURSES_FOLLOWED_FILTER } from '../../../../api/documentCenter/courses.api';
import { isFilterSet } from '../../../common/widget/MasterWithDetail/Master';
import { separateStringList } from '../../../../utils/core/string/separatedStringList';
import
getUniqueTypeaheadFilterValuesFromListItems
    from '../../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import CheckboxesOrTypeaheadFilter from '../../../common/input/CheckboxesOrTypeaheadFilter/index';
import DetailHeader from '../shared/header';
import DetailContent from './detail/content';
import DetailFooter from './detail/footer';
import {
    DetailContent as AttendantDetailContent,
    DetailHeader as AttendantDetailHeader,
} from './attendant';
import ListItemActions from '../../../common/list/ListItemActions';
import Button from '../../../common/buttons/Button';
import Icon from '../../../common/icons/Icon';
import { AsyncStatus } from '../../../../models/general/redux';
import { fetchCourseSessionCertificatesActions } from '../../../../redux/documentCenter/courses/actions';
import { getSelectedSeatCompanyCode } from '../../../../redux/company/selected/selectors';
import Loader from '../../../common/waiting/Loader';
import NoDocumentsFoundErrorDialog from '../../../common/modals/NoDocumentsFoundErrorDialog';

const BASE_NAME = 'courses-followed';

const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;

interface IColumnNames {
    name: string;
    type: string;
    location: string;
    coordinator: string;
    startDate: string;
    startDateSort: string;
    endDate: string;
    endDateSort: string;
    actions: string;
}

interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    isShowAll: boolean;
    location: string;
}

interface IPrivateProps {
    onDownloadCertificates: (coursesOrganizedId: number) => void;
    isDownloadingCertificates: boolean;
}

type TFollowedCoursesListProps = IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>;

const COLUMNS: ListColumns<IColumnNames> = {
    name: {
        label: <Translate msg="document_center.courses.followed.columns.name" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    type: {
        label: <Translate msg="document_center.courses.followed.columns.type" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 10,
    },
    location: {
        label: <Translate msg="document_center.courses.followed.columns.location" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 21,
    },
    coordinator: {
        label: <Translate msg="document_center.courses.followed.columns.coordinator" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    startDate: {
        label: <Translate msg="document_center.courses.followed.columns.start" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 11,
        sortValue: (listItem) => listItem.columns.startDateSort,
    },
    startDateSort: {
        hide: true,
        sortable: false,
        percentWidth: 0,
    },
    endDate: {
        label: <Translate msg="document_center.courses.followed.columns.end" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 11,
        sortValue: (listItem) => listItem.columns.endDateSort,
    },
    endDateSort: {
        hide: true,
        sortable: false,
        percentWidth: 0,
    },
    actions: {
        sortable: false,
        percentWidth: 7,
    },
};

class CoursesFollowed extends PureComponent<IPrivateProps> {
    public render() {
        return (
            <>
                <MasterWithDetail
                    baseName={BASE_NAME}
                    getDefaultQueryParams={getDefaultQueryParams}
                    masterConfig={{
                        routeKey: ROUTE_KEYS.R_COURSES_FOLLOWED,
                        asyncInfoSelector: getCoursesFollowedAsyncInfo,
                        dataSelector: getCoursesFollowed,
                        transformData: mapCoursesToListItems,
                        transformFilterValuesToActiveFilters,
                        renderContent: (renderProps: TFollowedCoursesListProps) =>
                            <CoursesList {...renderProps} {...this.props} />,
                        clientSideSearchOfListData: {
                            searchFilterName: 'search',
                            columnsConfig: COLUMNS,
                        },
                        clientSideFilterOfListData,
                        filterValidationSchema: startEndDateSchema,
                    }}
                    headerConfig={{
                        renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                            <SearchContent {...renderProps} />,
                        renderFilterContent:
                            (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                                <FilterContent {...renderProps} />,
                        exportButton: {
                            baseFilename: 'courses-followed',
                            listItemIdExtractor: toListId,
                            mapListRowForExport,
                        },
                    }}
                    detailConfig={{
                        levels: [
                            {
                                level: 1,
                                details: [{
                                    routeKey: ROUTE_KEYS.R_COURSES_FOLLOWED_DETAIL,
                                    asyncInfoSelector: getCoursesFollowedAsyncInfo,
                                    idRouteParamName: 'coursesOrganizedId',
                                    dataSelector: getSelectedFollowedCourse,
                                    renderContent: (renderProps: IRenderDetailContentProps<ICourse>) =>
                                        <DetailContent
                                            {...renderProps}
                                            // eslint-disable-next-line max-len
                                            followedCoursesAttendantRouteKey={ROUTE_KEYS.R_COURSES_FOLLOWED_DETAIL_ATTENDANT}
                                        />,
                                    renderHeader: (renderProps: IRenderDetailHeaderProps<ICourse>) =>
                                        <DetailHeader {...renderProps} />,
                                    renderFooter: (renderProps: IRenderDetailFooterProps<ICourse>) =>
                                        <DetailFooter {...renderProps} />,
                                }],
                            },
                            {
                                level: 2,
                                details: [{
                                    routeKey: ROUTE_KEYS.R_COURSES_FOLLOWED_DETAIL_ATTENDANT,
                                    asyncInfoSelector: getCoursesFollowedAsyncInfo,
                                    idRouteParamName: 'employeeId',
                                    dataSelector: getSelectedCourseSessionAttendant,
                                    renderContent: (renderProps: IRenderDetailContentProps<ICourseSessionAttendant>) =>
                                        <AttendantDetailContent {...renderProps} />,
                                    renderHeader: (renderProps: IRenderDetailHeaderProps<ICourseSessionAttendant>) =>
                                        <AttendantDetailHeader {...renderProps} />,
                                }],
                            },
                        ],
                    }}
                    footerConfig={{
                        shouldRenderShowAllButton,
                    }}
                />
            </>
        );
    }
}

function toListId(course: ICourse) {
    return course.coursesOrganizedId;
}

function mapCoursesToListItems(masterData: ICourse[]): ListItem<IColumnNames>[] {
    return masterData
        .map((course) => {
            return {
                id: toListId(course),
                columns: {
                    name: course.courseName,
                    type: course.courseType,
                    location: course.address ? formatAddress(course.address) : null,
                    coordinator: course.coordinator,
                    startDate: formatDateForDisplay(course.startDate),
                    startDateSort: course.startDate,
                    endDate: formatDateForDisplay(course.endDate),
                    endDateSort: course.endDate,
                    actions: null,
                },
            };
        },
        );
}

function mapListRowForExport(course: ICourse) {
    const {
        amountDays,
        address,
        company: { companyCode },
        company: { name },
        courseName,
        courseType,
        endDate,
        startDate,
    } = course;

    return {
        company: {
            companyCode,
            name,
        },
        courseName,
        courseType,
        address: address ? formatAddress(address) : '', // address can be empty
        startDate,
        endDate,
        amountDays,
        /*
            TODO: amount of employees that showed up
            TODO: amount of employees succeeded
        */
    };
}

function clientSideFilterOfListData(
    filterProps: IClientSideFilterOfListDataProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const { listItems, filterValues } = filterProps;

    return listItems
        .filter((listItem) => {
            if (!isFilterSet(filterValues.location)) {
                return true;
            }

            const locations = separateStringList(filterValues.location);
            return locations.includes(listItem.columns.location && listItem.columns.location.toString());
        });
}

export function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('document_center.courses.followed.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="document_center.courses.followed.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
        masterData: allListItems,
    } = renderProps;

    const possibleLocations = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'location',
        'location',
    );

    return (
        <div>
            <CheckboxesOrTypeaheadFilter
                filterName="location"
                labelTranslationKey="document_center.courses.followed.filter.location"
                possibleFilterItems={possibleLocations}
                actualFilterValue={formRenderProps.values.location}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'location',
                    newFilterValue,
                )}
            />
            <StartEndDateFilter
                translationKeyPrefix="document_center.courses.followed.filter"
                formRenderProps={formRenderProps}
                maxDate={DEFAULT_COURSES_FOLLOWED_FILTER.endDate}
            />
        </div>
    );
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'document_center.courses.followed.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            location: {
                show: true,
                translationKeySuffixOverride: 'location',
                multiple: {
                    enable: true,
                },
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_COURSES_FOLLOWED_FILTER.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_COURSES_FOLLOWED_FILTER.startDate,
            },
        },
    });
}

class CoursesList extends
    PureComponent<TFollowedCoursesListProps & IPrivateProps> {

    private columns: ListColumns<IColumnNames> = clone(COLUMNS);

    constructor(props: TFollowedCoursesListProps & IPrivateProps) {
        super(props);

        this.columns.actions.render = this.renderListItemActions.bind(this);
        this.onDownloadCertificates = this.onDownloadCertificates.bind(this);
    }

    private renderListItemActions(listItem: ListItem<IColumnNames>) {
        const {
            isDownloadingCertificates,
        } = this.props;

        return (
            <ListItemActions>
                <Button
                    id="download-certificates-button"
                    onClick={(e) => this.onDownloadCertificates(e, listItem)}
                    disabled={isDownloadingCertificates}
                >
                    <span>
                        <Translate msg="document_center.courses.followed.detail.footer.download_certificates" />
                    </span>
                    <Icon circle typeName="download-file" />
                </Button>
            </ListItemActions>
        );
    }

    private onDownloadCertificates(e: MouseEvent<HTMLElement>, listItem: ListItem<IColumnNames>) {
        e.preventDefault();
        e.stopPropagation();

        this.props.onDownloadCertificates(listItem.id as number);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            onItemSelected,
            selectedItemId,
            filterValues,
            footer,
            isDownloadingCertificates,
        } = this.props;

        const INITIAL_SORT: ISortedColumn<IColumnNames> = {
            name: 'startDate',
            sortOrder: SortOrder.Descending,
        };
        return (
            <>
                <ListWithSorting
                    columns={this.columns}
                    items={clientSideFilteredlistItems}
                    name={BASE_NAME}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    selectedItemIds={selectedItemId ? [selectedItemId] : []}
                    onItemRowClicked={onItemSelected}
                    maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                    footer={footer}
                    initialSort={INITIAL_SORT}
                />
                {!selectedItemId && <Loader show={isDownloadingCertificates} />}
                {!selectedItemId && (
                    <NoDocumentsFoundErrorDialog
                        asyncInfoSelector={getFetchCourseSessionCertificatesAsyncInfo}
                    />
                )}
            </>
        );
    }
}

function getDefaultQueryParams({ isShowAll }: { isShowAll: boolean }) {
    return isShowAll ? {
        ...DEFAULT_COURSES_FOLLOWED_FILTER,
        isShowAll,
    } : DEFAULT_COURSES_FOLLOWED_FILTER;
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}

export default connect<IPrivateProps, TFollowedCoursesListProps>({
    stateProps: (state) => {
        const downloadAsyncInfo = getFetchCourseSessionCertificatesAsyncInfo(state);
        const isDownloadingCertificates = downloadAsyncInfo.status === AsyncStatus.Busy;

        return {
            isDownloadingCertificates,
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            onDownloadCertificates: (coursesOrganizedId: number) => {
                const state = getState();
                const companyCode = getSelectedSeatCompanyCode(state);

                dispatch(fetchCourseSessionCertificatesActions.trigger({ companyCode, coursesOrganizedId }));
            },
        };
    },
})(CoursesFollowed);
