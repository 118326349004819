import React, { PureComponent } from 'react';
import { clone } from 'ramda';
import dayjs from 'dayjs';

import '../medical-examinations.scss';
import {
    INoShowMedicalExamination,
} from '../../../../models/interventions/medicalExaminations';
import {
    getNoShowsMedicalExaminationsAsyncInfo,
    getNoShowsMedicalExaminatons,
} from '../../../../redux/medicalExamination/selectors';
import ROUTE_KEYS from '../../../../routeKeys';
import MasterWithDetail from '../../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps,
    IShouldRenderShowAllButtonProps,
    ITransformToActiveFiltersProps,
    IRenderSearchContentProps, IRenderFilterContentProps,
} from '../../../common/widget/MasterWithDetail/typings';
import ErrorPlaceholder from '../../../common/error/ErrorPlaceholder';
import FloatableTextInputWrapper from '../../../common/forms/FloatableTextInputWrapper';
import TextInput from '../../../common/input/TextInput';
import ListWithSorting from '../../../common/list/ListWithSorting';
import Translate from '../../../common/Translate';
import { ListColumns, ListItem, SortType, ISortedColumn, SortOrder } from '../../../../models/general/list';
import { DEFAULT_NO_SHOWS_MEDICAL_EXAMINATIONS_FILTERS } from '../../../../api/interventions/medicalExaminations.api';
import { formatPersonNameFormal } from '../../../../utils/formatting/formatPerson';
import { formatDateInLongFormat, formatDateForDisplay } from '../../../../utils/formatting/formatDate';
import { formatTimeOfDateForDisplay } from '../../../../utils/formatting/formatTime';
import { connect } from '../../..';
import { isAllSeatsSelected } from '../../../../redux/company/selected/selectors';
import { createGenericActiveFilters } from '../../../common/widget/MasterWithDetail/Master/ActiveFilters';
import StartEndDateFilter from '../../../common/input/StartEndDateFilter';
import { startEndDateSchema } from '../../../common/input/StartEndDateFilter/startEndDateSchema';
import { IStartEndDateFilterValues } from '../../../../models/ui/form';

const BASE_NAME = 'no-shows-med-exams';
const DEFAULT_NR_OF_RECORDS_TO_SHOW = 20;

interface IPrivateProps {
    isAllSeatsSelected: boolean;
}

interface IFilterValues extends IStartEndDateFilterValues {
    search: string;
    isShowAll: boolean;
}

interface IColumnNames {
    employee: string;
    planningsDate: string;
    planningsDateSort: string;
    planningsType: string;
    reasonAbsent: number;
    seat: string;
    seatCode: string;
}

type TNoShowsListProps = IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>;

const COLUMNS: ListColumns<IColumnNames> = {
    employee: {
        label: <Translate msg="interventions.medical_examinations.no_shows.columns.employee" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 25,
    },
    seat: {
        hide: true,
        percentWidth: null,
    },
    seatCode: {
        hide: true,
        percentWidth: null,
    },
    planningsType: {
        label: <Translate msg="interventions.medical_examinations.no_shows.columns.plannings_type" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 30,
    },
    planningsDate: {
        label: <Translate msg="interventions.medical_examinations.no_shows.columns.plannings_date" />,
        sortable: true,
        sortType: SortType.String,
        sortValue: (listItem: ListItem<IColumnNames>) => listItem.columns.planningsDateSort,
        percentWidth: 15,
    },
    planningsDateSort: {
        hide: true,
        percentWidth: null,
    },
    reasonAbsent: {
        label: <Translate msg="interventions.medical_examinations.no_shows.columns.absent_reason" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 30,
    },
};

const ALL_SEATS_COLUMNS: ListColumns<IColumnNames> = {
    employee: {
        ...COLUMNS.employee,
        percentWidth: 20,
    },
    seat: {
        label: <Translate msg="interventions.medical_examinations.no_shows.columns.seat" />,
        sortable: true,
        sortType: SortType.String,
        percentWidth: 20,
    },
    seatCode: {
        ...COLUMNS.seatCode,
    },
    planningsType: {
        ...COLUMNS.planningsType,
        percentWidth: 20,
    },
    planningsDate: {
        ...COLUMNS.planningsDate,
        percentWidth: 15,
    },
    planningsDateSort: {
        ...COLUMNS.planningsDateSort,
    },
    reasonAbsent: {
        ...COLUMNS.reasonAbsent,
        percentWidth: 25,
    },

};

const INITIAL_SORT: ISortedColumn<IColumnNames> = {
    name: 'planningsDate',
    sortOrder: SortOrder.Descending,
};

function NoShowsMedicalExaminations(props: IPrivateProps) {
    return (
        <MasterWithDetail
            baseName={BASE_NAME}
            getDefaultQueryParams={getDefaultQueryParams}
            masterConfig={{
                routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_NO_SHOWS,
                asyncInfoSelector: getNoShowsMedicalExaminationsAsyncInfo,
                dataSelector: getNoShowsMedicalExaminatons,
                transformData: mapMedicalExaminationsToListItems,
                transformFilterValuesToActiveFilters,
                renderContent: (renderProps: TNoShowsListProps) =>
                    <ExaminationsList {...renderProps} isAllSeatsSelected={props.isAllSeatsSelected} />,
                clientSideSearchOfListData: {
                    searchFilterName: 'search',
                    columnsConfig: props.isAllSeatsSelected ? ALL_SEATS_COLUMNS : COLUMNS,
                },
                filterValidationSchema: startEndDateSchema,
            }}
            headerConfig={{
                renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                    <SearchContent {...renderProps} />,
                renderFilterContent:
                    (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                        <FilterContent {...renderProps} />,
                exportButton: {
                    baseFilename: 'medical-examinations-noshows',
                    listItemIdExtractor: toListId,
                    mapListRowForExport,
                },
            }}
            footerConfig={{
                shouldRenderShowAllButton,
            }}
        />
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        return {
            isAllSeatsSelected: isAllSeatsSelected(state),
        };
    },
})(NoShowsMedicalExaminations);

/**
 * We don't get a unique id from the backend, so we generate one ourselves.
 */
function toListId(noShow: INoShowMedicalExamination) {
    const { employee, planningsDate } = noShow;
    return `${employee.name}-${employee.firstName}-${planningsDate}`;
}

function mapMedicalExaminationsToListItems(masterData: INoShowMedicalExamination[]): ListItem<IColumnNames>[] {
    return masterData
        .map((examination) => ({
            id: toListId(examination),
            columns: {
                employee: formatPersonNameFormal(examination.employee),
                planningsType: examination.planningsType,
                planningsDate: formatDateInLongFormat(examination.planningsDate),
                planningsDateSort: examination.planningsDate,
                reasonAbsent: examination.reasonAbsent,
                seat: examination.company.name,
                seatCode: examination.company.companyCode,
            },
        }));
}

function mapListRowForExport(examination: INoShowMedicalExamination) {
    const {
        company: { companyCode },
        company: { name: companyName },
        planningsType,
        employee: { name: employeeName },
        employee: { firstName },
        planningsDate,
        reasonAbsent,
    } = examination;

    return {
        company: {
            companyCode,
            name: companyName,
        },
        employee: {
            name: employeeName,
            firstName,
        },
        examinationReason: planningsType,
        planningsDate: {
            date: formatDateForDisplay(dayjs(planningsDate).toDate()),
            time: formatTimeOfDateForDisplay(planningsDate),
        },
        reasonAbsent,
    };
}

class ExaminationsList extends PureComponent<TNoShowsListProps & IPrivateProps> {
    private columns: ListColumns<IColumnNames> = clone(COLUMNS);
    private allSeatsColumns: ListColumns<IColumnNames> = clone(ALL_SEATS_COLUMNS);

    constructor(props: TNoShowsListProps & IPrivateProps) {
        super(props);
    }

    public render() {
        const {
            masterAsyncInfo,
            masterData: clientSideFilteredlistItems,
            filterValues,
            footer,
            isAllSeatsSelected,
        } = this.props;

        return (
            <>
                <ListWithSorting
                    columns={isAllSeatsSelected ? this.allSeatsColumns : this.columns}
                    items={clientSideFilteredlistItems}
                    name={BASE_NAME}
                    errorMessage={masterAsyncInfo.error &&
                        <ErrorPlaceholder apiError={masterAsyncInfo.error} />}
                    initialSort={INITIAL_SORT}
                    maxNrOfRecordsToShow={filterValues.isShowAll ? undefined : DEFAULT_NR_OF_RECORDS_TO_SHOW}
                    footer={footer}
                />
            </>
        );
    }
}

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator('interventions.medical_examinations.no_shows.filter.search')}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg="interventions.medical_examinations.no_shows.filter.search" />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
    } = renderProps;

    return (
        <div>
            <StartEndDateFilter
                translationKeyPrefix="interventions.medical_examinations.no_shows.filter"
                formRenderProps={formRenderProps}
            />
        </div>
    );
}

function getDefaultQueryParams({ isShowAll }: {isShowAll: boolean}) {
    return isShowAll ? {
        ...DEFAULT_NO_SHOWS_MEDICAL_EXAMINATIONS_FILTERS,
        isShowAll,
    } : DEFAULT_NO_SHOWS_MEDICAL_EXAMINATIONS_FILTERS;
}

function transformFilterValuesToActiveFilters(
    transformProps: ITransformToActiveFiltersProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    return createGenericActiveFilters<IFilterValues, IColumnNames>({
        transformProps,
        translationKeyPrefix: 'interventions.medical_examinations.no_shows.active_filter',
        groupConfig: {
            filterKeys: ['endDate', 'startDate'],
            translationKeySuffix: 'period',
            formatFilterValueForPlaceholder: formatDateForDisplay,
        },
        filters: {
            isShowAll: {
                show: false,
            },
            search: {
                show: true,
            },
            endDate: {
                show: true,
                defaultValue: DEFAULT_NO_SHOWS_MEDICAL_EXAMINATIONS_FILTERS.endDate,
            },
            startDate: {
                show: true,
                defaultValue: DEFAULT_NO_SHOWS_MEDICAL_EXAMINATIONS_FILTERS.startDate,
            },
        },
    });
}

function shouldRenderShowAllButton(
    shouldRenderProps: IShouldRenderShowAllButtonProps<ListItem<IColumnNames>[], IFilterValues>,
) {
    const {
        masterData: clientSideFilteredlistItems,
        filterValues,
    } = shouldRenderProps;

    return !filterValues.isShowAll && clientSideFilteredlistItems.length > DEFAULT_NR_OF_RECORDS_TO_SHOW;
}
