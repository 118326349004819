import React from 'react';
import { path } from 'ramda';

import { IPlannedMedicalExamination } from '../../../../../models/interventions/medicalExaminations/planned';
import {
    getPlannedMedicalExaminationsAsyncInfo,
    getPlannedMedicalExaminations,
    getSelectedPlannedMedicalExamination,
    getSelectedPlannedMedicalExaminationAsyncInfo,
} from '../../../../../redux/medicalExamination/selectors';
import {
    getCreateConvocationsAsyncInfo,
} from '../../../../../redux/employee/documents/selectors';
import ROUTE_KEYS from '../../../../../routeKeys';
import MasterWithDetail from '../../../../common/widget/MasterWithDetail';
import {
    IRenderMasterContentProps,
    IRenderActionContentProps,
    IRenderSearchContentProps,
    IRenderFilterContentProps,
    IRenderDetailHeaderProps,
    IRenderDetailContentProps,
    IIsSelectAllCheckboxSelected,
    IOnSelectAllChangeProps,
    IRenderSelectAllTranslateProps,
} from '../../../../common/widget/MasterWithDetail/typings';
import { connect } from '../../../../index';
import FloatableTextInputWrapper from '../../../../common/forms/FloatableTextInputWrapper';
import CheckboxesOrTypeaheadFilter from '../../../../common/input/CheckboxesOrTypeaheadFilter';
import TextInput from '../../../../common/input/TextInput';
import Translate from '../../../../common/Translate';
import { ListItem, ISortedColumn, ListItemId } from '../../../../../models/general/list';
import { formatDateForBackend } from '../../../../../utils/formatting/formatDate';
import getUniqueTypeaheadFilterValuesFromListItems
    from '../../../../../utils/list/getUniqueTypeaheadFilterValuesFromListItems';
import { DetailHeader, DetailContent } from '../detail';
import { createConvocationsActions } from '../../../../../redux/employee/documents/actions';
import { AsyncStatus } from '../../../../../models/general/redux';
import { ICreateConvocationsPayload, TConvocationRequestType } from '../../../../../models/general/documents';
import ListActionButton from '../../../../common/buttons/ListActionButton/index';
import StartEndDateFilter from '../../../../common/input/StartEndDateFilter';
import StartEndHourFilter from '../../../../common/input/StartEndHourFilter';
import { startEndDateAndHoursSchema } from '../../../../common/input/StartEndDateFilter/startEndDateAndHoursSchema';
import { MAX_NR_OF_DOCUMENT_DOWNLOADS } from '../../../../../config/general.config';
import { now } from '../../../../../utils/core/date/getSpecificDate';
import sortListItems from '../../../../../utils/list/sortListItems';

import {
    IColumnNames,
    IComponentState,
    IPrivateProps,
    IFilterValues,
} from './PlannedMedicalExaminations.type';
import {
    BASE_NAME,
    CLASS_NAME,
    COLUMNS,
    EXPORT_BASE_FILENAME,
    INITIAL_SORT,
    TRANSLATION_PREFIX,
} from './PlannedMedicalExaminations.const';
import {
    clientSideFilterOfListData,
    fetchAllExportDataApiCall,
    getDefaultQueryParams,
    getMaxNrSelected,
    mapMedicalExaminationsToListItems,
    transformFilterValuesToActiveFilters,
    shouldRenderShowAllButton,
} from './PlannedMedicalExaminations.helper';
import {
    PlannedMedicalExaminationsList,
} from './PlannedMedicalExaminationsList/PlannedMedicalExaminationsList.component';

class PlannedMedicalExaminationsComp extends React.Component<IPrivateProps, IComponentState> {
    constructor(props: IPrivateProps) {
        super(props);

        this.state = {
            selectedIds: [],
            sortedColumn: INITIAL_SORT,
        };

        this.setSortedColumn = this.setSortedColumn.bind(this);
        this.setSelectedIds = this.setSelectedIds.bind(this);
        this.isSelectAllCheckboxSelected = this.isSelectAllCheckboxSelected.bind(this);
        this.onSelectAllChange = this.onSelectAllChange.bind(this);
        this.renderSelectAllTranslate = this.renderSelectAllTranslate.bind(this);
    }

    render() {
        const { plannedMedicalExaminations } = this.props;

        return (
            <MasterWithDetail
                baseName={BASE_NAME}
                className={CLASS_NAME}
                getDefaultQueryParams={getDefaultQueryParams}
                masterConfig={{
                    routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED,
                    asyncInfoSelector: getPlannedMedicalExaminationsAsyncInfo,
                    dataSelector: getPlannedMedicalExaminations,
                    transformData: mapMedicalExaminationsToListItems,
                    transformFilterValuesToActiveFilters,
                    renderContent: (renderProps: IRenderMasterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                        <PlannedMedicalExaminationsList
                            {...renderProps}
                            {...this.props}
                            {...this.state}
                            setSortedColumn={this.setSortedColumn}
                            setSelectedIds={this.setSelectedIds}
                            plannedMedicalExaminations={plannedMedicalExaminations}
                        />,
                    clientSideSearchOfListData: {
                        searchFilterName: 'search',
                        columnsConfig: COLUMNS,
                    },
                    clientSideFilterOfListData,
                    filterValidationSchema: startEndDateAndHoursSchema,
                }}
                detailConfig={{
                    routeKey: ROUTE_KEYS.R_MEDICAL_EXAMINATIONS_PLANNED_DETAIL,
                    asyncInfoSelector: getSelectedPlannedMedicalExaminationAsyncInfo,
                    idRouteParamName: 'timeSlotId',
                    dataSelector: getSelectedPlannedMedicalExamination,
                    renderHeader: (renderProps: IRenderDetailHeaderProps<IPlannedMedicalExamination>) =>
                        <DetailHeader {...renderProps} />,
                    renderContent: (renderProps: IRenderDetailContentProps<IPlannedMedicalExamination>) =>
                        <DetailContent {...renderProps} />,
                }}
                headerConfig={{
                    selectAllCheckbox: {
                        renderTranslateComponent: this.renderSelectAllTranslate,
                        isSelected: this.isSelectAllCheckboxSelected,
                        onSelectionChange: this.onSelectAllChange,
                    },
                    renderSearchContent: (renderProps: IRenderSearchContentProps<IFilterValues>) =>
                        <SearchContent {...renderProps} />,
                    renderFilterContent:
                        (renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) =>
                            <FilterContent {...renderProps} />,
                    renderActionContent:
                        (renderProps: IPrivateProps & IRenderActionContentProps<ListItem<IColumnNames>[]>) =>
                            <ActionContent
                                {...renderProps}
                                {...this.props}
                                {...this.state}
                            />,
                    exportButton: {
                        baseFilename: EXPORT_BASE_FILENAME,
                        fetchAllExportData: {
                            isFetchNeeded: this.isFetchAllExportDataNeeded,
                            apiCall: fetchAllExportDataApiCall,
                            willResultAlreadyBeAFile: true,
                        },
                    },
                }}
                footerConfig={{
                    shouldRenderShowAllButton,
                }}
            />
        );
    }

    private isFetchAllExportDataNeeded(): boolean {
        return true;
    }

    private setSortedColumn(sortedColumn: ISortedColumn<IColumnNames>) {
        this.setState({
            sortedColumn,
        });
    }

    private setSelectedIds(selectedIds: ListItemId[]) {
        this.setState({
            selectedIds,
        });
    }

    private isSelectAllCheckboxSelected(
        isSelectedProps: IIsSelectAllCheckboxSelected<ListItem<IColumnNames>[], IFilterValues>,
    ) {
        const { selectedIds } = this.state;
        const {
            masterData: clientSideFilteredlistItems,
            filterValues,
        } = isSelectedProps;

        if (selectedIds.length <= 0) {
            return false;
        }

        const maxNrSelected = getMaxNrSelected(filterValues);
        if (clientSideFilteredlistItems['length'] < maxNrSelected) {
            return selectedIds.length === clientSideFilteredlistItems['length'];
        }

        return selectedIds.length === maxNrSelected;
    }

    private onSelectAllChange(selectAllProps: IOnSelectAllChangeProps<ListItem<IColumnNames>[], IFilterValues>) {
        const {
            isSelected,
            masterData: clientSideFilteredlistItems,
            filterValues,
        } = selectAllProps;

        const maxNrSelected = getMaxNrSelected(filterValues);

        const selectedItems = isSelected
            ? clientSideFilteredlistItems.length > maxNrSelected
                // eslint-disable-next-line max-len
                ? sortListItems(clientSideFilteredlistItems, this.state.sortedColumn, COLUMNS[this.state.sortedColumn.name])
                    .slice(0, maxNrSelected)
                : clientSideFilteredlistItems
            : [];

        this.setSelectedIds(selectedItems.map((listItem) => listItem.id as string));
    }

    private renderSelectAllTranslate(props: IRenderSelectAllTranslateProps) {
        const {
            masterData: clientSideFilteredlistItems,
        } = props;
        if (clientSideFilteredlistItems['length'] <= MAX_NR_OF_DOCUMENT_DOWNLOADS) {
            return (
                <Translate msg={`${TRANSLATION_PREFIX}.actions.select_all_invitations`} />
            );
        }

        return (
            <Translate
                msg={`${TRANSLATION_PREFIX}.actions.select_all_invitations_with_max`}
                placeholders={{
                    amount: MAX_NR_OF_DOCUMENT_DOWNLOADS,
                }}
            />
        );
    }
}

export const PlannedMedicalExaminations = connect<IPrivateProps>({
    stateProps: (state) => {
        const isDownloadingDocument = getCreateConvocationsAsyncInfo(state).status === AsyncStatus.Busy;
        const plannedMedicalExaminations = getPlannedMedicalExaminations(state);

        return {
            isDownloadingDocument,
            plannedMedicalExaminations,
        };
    },
    dispatchProps: (dispatch) => ({
        onDownloadClick: (item: ListItem<IColumnNames>) => {
            const planningRequestMsId = path<number>(['columns', 'planningRequestId'], item);
            const employmentId = path<number>(['columns', 'employmentId'], item);

            dispatch(createConvocationsActions.trigger({
                convocations: [{
                    requestType: 'Letter' as TConvocationRequestType,
                    timeSlotId: item.id as number,
                    ...!!(planningRequestMsId) && {
                        planningRequestMsId,
                    },
                    ...!!(!planningRequestMsId && employmentId) && {
                        employmentId,
                    },
                }],
            }));
        },
        onDownloadSelectedClick: (listItems: ListItem<IColumnNames>[]) => {
            dispatch(createConvocationsActions.trigger({
                convocations: listItems.map(item => {
                    const planningRequestMsId = path<number>(['columns', 'planningRequestId'], item);
                    const employmentId = path<number>(['columns', 'employmentId'], item);

                    return {
                        requestType: 'Letter' as TConvocationRequestType,
                        timeSlotId: item.id as number,
                        ...!!(planningRequestMsId) && {
                            planningRequestMsId,
                        },
                        ...!!(!planningRequestMsId && employmentId) && {
                            employmentId,
                        },
                    } as ICreateConvocationsPayload;
                }),
            }));
        },
    }),
})(PlannedMedicalExaminationsComp);

function SearchContent(renderProps: IRenderSearchContentProps<IFilterValues>) {
    const {
        formRenderProps,
        translator,
    } = renderProps;

    return (
        <FloatableTextInputWrapper floatLabel>
            <TextInput
                id="filter-global-search"
                name="search"
                placeholder={translator(`${TRANSLATION_PREFIX}.filter.search`)}
                value={formRenderProps.values.search || ''}
                onChange={formRenderProps.handleChange}
            />
            <label htmlFor="filter-global-search">
                <Translate msg={`${TRANSLATION_PREFIX}.filter.search`} />
            </label>
        </FloatableTextInputWrapper>
    );
}

function FilterContent(renderProps: IRenderFilterContentProps<ListItem<IColumnNames>[], IFilterValues>) {
    const {
        formRenderProps,
        // translator,
        masterData: allListItems,
    } = renderProps;

    const possibleLocations = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'locationId',
        'location',
    );
    const possibleReasons = getUniqueTypeaheadFilterValuesFromListItems<IColumnNames>(
        allListItems,
        'examinationReasonId',
        'examinationReason',
    );

    return (
        <div>
            <CheckboxesOrTypeaheadFilter
                filterName="location"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.location`}
                possibleFilterItems={possibleLocations}
                actualFilterValue={formRenderProps.values.locationIds}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'locationIds',
                    newFilterValue,
                )}
            />

            <CheckboxesOrTypeaheadFilter
                filterName="reason"
                labelTranslationKey={`${TRANSLATION_PREFIX}.filter.reason`}
                possibleFilterItems={possibleReasons}
                actualFilterValue={formRenderProps.values.reasonIds}
                onChange={(newFilterValue) => formRenderProps.setFieldValue(
                    'reasonIds',
                    newFilterValue,
                )}
            />

            <StartEndDateFilter
                translationKeyPrefix={`${TRANSLATION_PREFIX}.filter`}
                formRenderProps={formRenderProps}
                minDate={formatDateForBackend(now())}
            />

            <StartEndHourFilter
                translationKeyPrefix={`${TRANSLATION_PREFIX}.filter`}
                formRenderProps={formRenderProps}
            />
        </div>
    );
}

function ActionContent(props: IRenderActionContentProps<ListItem<IColumnNames>[]> & IPrivateProps & IComponentState) {
    const {
        masterData: allListItems,
        onDownloadSelectedClick,
        selectedIds,
    } = props;

    const isDownloadSelectedEnabled = (allListItems && allListItems.length > 0)
        && (selectedIds && selectedIds.length > 0);

    return (
        <>
            <ListActionButton
                id="medical-examinations-planned-download-button"
                type="text"
                iconTypeName="download-file"
                translationKey={`${TRANSLATION_PREFIX}.actions.download_selected_invitation`}
                onClick={(e) => {
                    const itemsToDownload = allListItems.filter((item) => selectedIds.includes(item.id));
                    onDownloadSelectedClick(itemsToDownload);
                }}
                disabled={!isDownloadSelectedEnabled}
            />
        </>
    );
}
