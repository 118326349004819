import React from 'react';
import Dialog from '../../../common/modals/Dialog';
import Translate from '../../../common/Translate';
import { ICaseManager } from '../../../../models/contact/caseManager';
import { connect } from '../../..';
import { getCaseManager, getCaseManagerAsyncInfo } from '../../../../redux/contact/selectors';
import ContactInformation from '../../../contact/shared/ContactInformation';
import Loader from '../../../common/waiting/Loader';
import { AsyncStatus, IAsyncFieldInfo } from '../../../../models/general/redux';

import './dialog.scss';
import Button from '../../../common/buttons/Button';

interface IPublicProps {
    open: boolean;
    onCloseDialog: () => void;
}

interface IPrivateProps {
    caseManager: ICaseManager;
    caseManagerAsyncInfo: IAsyncFieldInfo;
}

function ReasonNotAllowedToPlanDialog(props: IPrivateProps & IPublicProps) {
    const {
        open,
        onCloseDialog,
        caseManager,
        caseManagerAsyncInfo,
    } = props;

    return (
        <Dialog
            show={open}
            onCloseIntent={() => onCloseDialog()}
            type="error"
        >
            <Loader show={caseManagerAsyncInfo.status === AsyncStatus.Busy}>
                <p>
                    <Translate
                        msg="interventions.medical_examinations.to_plan.reason_not_allowed_for_website.text"
                    />
                </p>
                <div className="reason-not-allowed-container">
                    <ContactInformation
                        contact={caseManager}
                        contactType={
                            <Translate msg="contact.overview.contacts.case_manager"/>
                        }
                    />
                    <Button
                        id="close-dialog"
                        typeName="secondary"
                        onClick={() => onCloseDialog()}
                    >
                        <Translate msg="OK" />
                    </Button>
                </div>
            </Loader>
        </Dialog>
    );
}

export default connect<IPrivateProps>({
    stateProps: (state) => {
        const caseManager = getCaseManager(state);
        return {
            caseManager,
            caseManagerAsyncInfo: getCaseManagerAsyncInfo(state),
        };
    },
})(ReasonNotAllowedToPlanDialog);
